function useBrowserStorage() {
  const getLocalStorageItem = (itemName: string) => {
    if (typeof window !== 'undefined') {
      const localStorageItem = localStorage.getItem(itemName);
      return typeof localStorageItem == 'string'
        ? JSON.parse(localStorageItem)
        : localStorageItem;
    }
  };

  const setSessionStorageItem = (
    itemName: string,
    itemValue: string | { [key: string]: string },
  ) => {
    if (typeof window !== 'undefined')
      sessionStorage.setItem(itemName, JSON.stringify(itemValue));
  };

  const removeSessionStorageItem = (itemName: string) => {
    if (typeof window !== 'undefined') sessionStorage.removeItem(itemName);
  };

  const getSessionStorageItem = (itemName: string) => {
    if (typeof window !== 'undefined') {
      const localStorageItem = sessionStorage.getItem(itemName);
      return typeof localStorageItem == 'string'
        ? JSON.parse(localStorageItem)
        : localStorageItem;
    }
  };

  const setLocalStorageItem = (
    itemName: string,
    itemValue: string | { [key: string]: string },
  ) => {
    if (typeof window !== 'undefined')
      localStorage.setItem(itemName, JSON.stringify(itemValue));
  };

  const removeLocalStorageItem = (itemName: string) => {
    if (typeof window !== 'undefined') localStorage.removeItem(itemName);
  };

  return {
    getLocalStorageItem,
    setLocalStorageItem,
    removeLocalStorageItem,
    getSessionStorageItem,
    setSessionStorageItem,
    removeSessionStorageItem,
  };
}

export { useBrowserStorage };
